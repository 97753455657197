import { DefaultTheme } from 'styled-components';

/**
 * @name 기본색상 선언
 */
const colors = {
  black: '#000',
  white: '#fff',
  red: '#e60012', //롯데마트GO
  red02: '#f7b2b7', //연한 빨강
  blue: '#0071ce', //스노우플랜
  green: '#009d49', //탄소중립
  label_skyblue: '#05c3f2', // 행사라벨
  label_green: '#3ac49b', // 행사라벨
  label_purple: '#ba20dc', // 행사라벨
  gray_bg: '#f6f6f6', //배경 회색
  gray_light: '#ececec', // 연한 회색
  gray_line: '#666666', // 진한 회색선
  gray01: '#666666', // 폰트 연한 gray 장차법 통합
  gray02: '#666666', // 폰트 중간 gray 장차법 통합
  gray03: '#666666', // 폰트 진한 gray 장차법 통합
};

export type ColorsTypes = typeof colors;

export const theme: DefaultTheme = {
  colors,
};
