import React from 'react';
import { StyledIcon } from './styles';

/**
 * @name 알림종 아이콘
 */
export const IcoBell = (): React.ReactElement => {
  return <StyledIcon className="ico_bell"></StyledIcon>;
};

/**
 * @name 알림종 빨간색 아이콘
 */
export const IcoBellRed = (): React.ReactElement => {
  return <StyledIcon className="ico_bell_red"></StyledIcon>;
};

/**
 * @name 햄버거메뉴 아이콘
 */
export const IcoHamburger = (): React.ReactElement => {
  return <StyledIcon className="ico_hamburger"></StyledIcon>;
};

/**
 * @name 포인트 아이콘
 */
export const IcoPoint = (): React.ReactElement => {
  return <StyledIcon className="ico_point"></StyledIcon>;
};

/**
 * @name 바코드 아이콘
 */
export const IcoBarcode = (): React.ReactElement => {
  return <StyledIcon className="ico_barcode"></StyledIcon>;
};

/**
 * @name 스마트영수증 아이콘
 */
export const IcoSmartReceipt = (): React.ReactElement => {
  return <StyledIcon className="ico_smartreceipt"></StyledIcon>;
};

/**
 * @name 전단 아이콘
 */
export const IcoLeaflet = (): React.ReactElement => {
  return <StyledIcon className="ico_leaflet"></StyledIcon>;
};

/**
 * @name 추천상품 아이콘
 */
export const IcoRecomeProd = (): React.ReactElement => {
  return <StyledIcon className="ico_recomprod"></StyledIcon>;
};

/**
 * @name MY 혜택 아이콘
 */
export const IcoMyBenefit = (): React.ReactElement => {
  return <StyledIcon className="ico_mybenefit"></StyledIcon>;
};

/**
 * @name 이벤트 아이콘
 */
export const IcoEvent = (): React.ReactElement => {
  return <StyledIcon className="ico_event"></StyledIcon>;
};

/**
 * @name 닫기 아이콘
 */
export const IcoClose = (): React.ReactElement => {
  return <StyledIcon className="ico_close"></StyledIcon>;
};

/**
 * @name 시계 아이콘
 */
export const IcoClock = (): React.ReactElement => {
  return <StyledIcon className="ico_clock"></StyledIcon>;
};

/**
 * @name 달력 아이콘
 */
export const IcoDate = (): React.ReactElement => {
  return <StyledIcon className="ico_date"></StyledIcon>;
};

/**
 * @name 드롭다운 아이콘
 */
export const IcoDropDown = (): React.ReactElement => {
  return <StyledIcon className="ico_dropdown"></StyledIcon>;
};

/**
 * @name 드롭다운 BOLD 아이콘(모서리 둥근)
 */
export const IcoDropDownBold = (): React.ReactElement => {
  return <StyledIcon className="ico_dropdown_bold"></StyledIcon>;
};

/**
 * @name 드롭다운 BOLD 아이콘(모서리 각진)
 */
export const IcoDropDownBold2 = (): React.ReactElement => {
  return <StyledIcon className="ico_dropdown_bold2"></StyledIcon>;
};

/**
 * @name 돋보기 아이콘
 */
export const IcoSearch = (): React.ReactElement => {
  return <StyledIcon className="ico_search"></StyledIcon>;
};

/**
 * @name 뒤로가기 아이콘
 */
export const IcoBack = (): React.ReactElement => {
  return <StyledIcon className="ico_back"></StyledIcon>;
};

/**
 * @name 홈 아이콘
 */
export const IcoHome = (): React.ReactElement => {
  return <StyledIcon className="ico_home"></StyledIcon>;
};
/**
 * @name 탄소중립 아이콘
 */
export const IcoCarbonNeutral = (): React.ReactElement => {
  return <StyledIcon className="ico_carbon_neutral"></StyledIcon>;
};

/**
 * @name 체크박스 off 아이콘
 */
export const IcoCheck = (): React.ReactElement => {
  return <StyledIcon className="ico_check_off"></StyledIcon>;
};

/**
 * @name 물음표 아이콘
 */
export const IcoQuestion = (): React.ReactElement => {
  return <StyledIcon className="ico_question"></StyledIcon>;
};

/**
 * @name 화살표 아이콘 검정
 */
export const IcoArrowBlack = (): React.ReactElement => {
  return <StyledIcon className="icon_arrow_black"></StyledIcon>;
};

/**
 * @name 화살표 아이콘 검정(라운드 배경)
 */
export const IconArrowBlackRound = (): React.ReactElement => {
  return <StyledIcon className="icon_arrow_black_round"></StyledIcon>;
};

/**
 * @name 화살표 아이콘 흰색
 */
export const IcoArrowWhite = (): React.ReactElement => {
  return <StyledIcon className="icon_arrow_white"></StyledIcon>;
};

/**
 * @name 화살표 아이콘 흰색(라운드 배경)
 */
export const IconArrowWhiteRound = (): React.ReactElement => {
  return <StyledIcon className="icon_arrow_white_round"></StyledIcon>;
};
/**
 * @name 화살표 아이콘 빨간색
 */
export const IcoArrowRed = (): React.ReactElement => {
  return <StyledIcon className="icon_arrow_red"></StyledIcon>;
};

/**
 * @name 입점매장점멸 아이콘
 */
export const IcoStatusOn = (): React.ReactElement => {
  return <StyledIcon className="icon_status_on"></StyledIcon>;
};

export const IcoStatusOff = (): React.ReactElement => {
  return <StyledIcon className="icon_status_off"></StyledIcon>;
};

/**
 * @name 위치 아이콘
 */
export const IcoLocation = (): React.ReactElement => {
  return <StyledIcon className="icon_location"></StyledIcon>;
};

/**
 * @name 전화 아이콘
 */
export const IcoTell = (): React.ReactElement => {
  return <StyledIcon className="icon_tell"></StyledIcon>;
};

/**
 * @name 하트 아이콘
 */
export const IcoHeart = (): React.ReactElement => {
  return <StyledIcon className="icon_heart"></StyledIcon>;
};
/**
 * @name 하트on 아이콘
 */
export const IcoHeartOn = (): React.ReactElement => {
  return <StyledIcon className="icon_heart_on"></StyledIcon>;
};
/**
 * @name 하트off 아이콘
 */
export const IcoHeartOff = (): React.ReactElement => {
  return <StyledIcon className="icon_heart_off"></StyledIcon>;
};

/**
 * @name 금지 아이콘
 */
export const IcoProhibition = (): React.ReactElement => {
  return <StyledIcon className="icon_prohibition"></StyledIcon>;
};

/**
 * @name 작은바코드 아이콘
 */
export const IcoSmallBarcode = (): React.ReactElement => {
  return <StyledIcon className="icon_small_barcode"></StyledIcon>;
};

/**
 * @name 회색체크 아이콘
 */
export const IcoCheckGray = (): React.ReactElement => {
  return <StyledIcon className="icon_check_gray"></StyledIcon>;
};

/**
 * @name 느낌표2 아이콘
 */
export const IcoNoti2 = (): React.ReactElement => {
  return <StyledIcon className="icon_noti_2"></StyledIcon>;
};

/**
 * @name 느낌표3 아이콘
 */
export const IcoNoti3 = (): React.ReactElement => {
  return <StyledIcon className="icon_noti_3"></StyledIcon>;
};

/**
 * @name 스토어 아이콘
 */
export const IcoNoDataStore = (): React.ReactElement => {
  return <StyledIcon className="icon_store"></StyledIcon>;
};

/**
 * @name 느낌표 아이콘
 */
export const IcoInfo = (): React.ReactElement => {
  return <StyledIcon className="icon_info"></StyledIcon>;
};

/**
 * @name 직원인증 아이콘
 */
export const IcoAuthEmployee = (): React.ReactElement => {
  return <StyledIcon className="icon_auth_employee"></StyledIcon>;
};

/**
 * @name 데이터없음 아이콘
 */
export const IcoNoData = (): React.ReactElement => {
  return <StyledIcon className="icon_no_data"></StyledIcon>;
};

/**
 * @name 미들dot 아이콘
 */
export const IcoDot = (): React.ReactElement => {
  return <StyledIcon className="icon_dot"></StyledIcon>;
};

/**
 * @name 필터 아이콘
 */
export const IcoFilter = (): React.ReactElement => {
  return <StyledIcon className="icon_filter"></StyledIcon>;
};

/**
 * @name 삭제 아이콘
 */
export const IcoClear = (): React.ReactElement => {
  return <StyledIcon className="icon_clear"></StyledIcon>;
};

/**
 * @name 리스트 아이콘
 */
export const IcoList = (): React.ReactElement => {
  return <StyledIcon className="icon_list"></StyledIcon>;
};

/**
 * @name 그리드 아이콘
 */
export const IcoGrid = (): React.ReactElement => {
  return <StyledIcon className="icon_grid"></StyledIcon>;
};

/**
 * @name 마트 로고 아이콘
 */
export const IcoLogoReceipt = (): React.ReactElement => {
  return <StyledIcon className="icon_logo_receipt"></StyledIcon>;
};

/**
 * @name 라인 아이콘
 */
export const IcoRuledLine = (): React.ReactElement => {
  return <StyledIcon className="icon_ruled_line"></StyledIcon>;
};

/**
 * @name 에러 아이콘
 */
export const IcoLoginError = (): React.ReactElement => {
  return <StyledIcon className="icon_login_error"></StyledIcon>;
};

/**
 * @name 리스트없음 아이콘
 */
export const IcoNoList = (): React.ReactElement => {
  return <StyledIcon className="icon_no_list"></StyledIcon>;
};

/**
 * @name 상품없음 아이콘
 */
export const IcoNoProd = (): React.ReactElement => {
  return <StyledIcon className="icon_no_prod"></StyledIcon>;
};

/**
 * @name 전단보기 아이콘
 */
export const IcoLeaflet2 = (): React.ReactElement => {
  return <StyledIcon className="icon_leaflet2"></StyledIcon>;
};

/**
 * @name 캘린더 아이콘
 */
export const IcoCalender = (): React.ReactElement => {
  return <StyledIcon className="icon_calender"></StyledIcon>;
};
/**
 * @name 블랙캘린더 아이콘
 */
export const IcoCalenderBlack = (): React.ReactElement => {
  return <StyledIcon className="icon_calender_black"></StyledIcon>;
};
/**
 * @name 리셋 아이콘
 */
export const IcoReset = (): React.ReactElement => {
  return <StyledIcon className="icon_reset"></StyledIcon>;
};
/**
 * @name 파랑화살표 아이콘
 */
export const IcoBlueArrow = (): React.ReactElement => {
  return <StyledIcon className="icon_big_arrow_blue"></StyledIcon>;
};
/**
 * @name 검정화살표 아이콘
 */
export const IcoBlackArrow = (): React.ReactElement => {
  return <StyledIcon className="icon_big_arrow_black"></StyledIcon>;
};

/**
 * @name 스캔앤고 완료 알림 아이콘1
 */
export const IcoScanInfo1 = (): React.ReactElement => {
  return <StyledIcon className="icon_scan_info01"></StyledIcon>;
};
/**
 * @name 스캔앤고 완료 알림 아이콘1
 */
export const IcoScanInfo2 = (): React.ReactElement => {
  return <StyledIcon className="icon_scan_info02"></StyledIcon>;
};
/**
 * @name 스캔앤고 완료 알림 아이콘1
 */
export const IcoScanInfo3 = (): React.ReactElement => {
  return <StyledIcon className="icon_scan_info03"></StyledIcon>;
};
/**
 * @name 완료 아이콘
 */
export const IcoDone = (): React.ReactElement => {
  return <StyledIcon className="icon_done"></StyledIcon>;
};
/**
 * @name 다운로드 아이콘
 */
export const IcoDownLoadBlack = (): React.ReactElement => {
  return <StyledIcon className="icon_download_black"></StyledIcon>;
};
/**
 * @name 다운로드 아이콘
 */
export const IcoDownLoadWhite = (): React.ReactElement => {
  return <StyledIcon className="icon_download_white"></StyledIcon>;
};
/**
 * @name 다운로드 아이콘
 */
export const IcoDownLoadDarkGray = (): React.ReactElement => {
  return <StyledIcon className="icon_download_dark_gray"></StyledIcon>;
};
/**
 * @name 다운로드 비활성화 아이콘
 */
export const IcoDownLoadGray = (): React.ReactElement => {
  return <StyledIcon className="icon_download_gray"></StyledIcon>;
};
/**
 * @name 화살표 아이콘(선)
 */
export const IcoLineBlack = (): React.ReactElement => {
  return <StyledIcon className="icon_line_black"></StyledIcon>;
};
/**
 * @name 앱카드 닫기 아이콘
 */
export const IcoAppCardClose = (): React.ReactElement => {
  return <StyledIcon className="icon_appcard_close"></StyledIcon>;
};
/**
 * @name 재생 아이콘
 */
export const IcoPlay = (): React.ReactElement => {
  return <StyledIcon className="icon_play"></StyledIcon>;
};
/**
 * @name 멈춤 아이콘
 */
export const IcoPause = (): React.ReactElement => {
  return <StyledIcon className="icon_pause"></StyledIcon>;
};
/**
 * @name 스크롤탑 아이콘
 */
export const IcoScrollTop = (): React.ReactElement => {
  return <StyledIcon className="icon_scroll_top"></StyledIcon>;
};
/**
 * @name QR이벤트 쿠폰 아이콘
 */
export const IcoEventCoupon = (): React.ReactElement => {
  return <StyledIcon className="icon_event_coupon"></StyledIcon>;
};
