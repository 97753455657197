import { thejamsilFont } from 'src/styles/font';
import { rem } from 'src/styles/function';
import { IMAGE_ROOT } from 'src/utils/constants';
import styled, { css, keyframes } from 'styled-components';
const IMG_DEFAULT_PATH = IMAGE_ROOT + '/default';

/**
 * @name 전체메뉴 애니메이션 키프레임 컴포넌트
 */
export const menu_open_move = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

export const menu_close_move = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`;
/**
 * @name 전체메뉴 메뉴 스타일 컴포넌트
 */
export const StyledSideMenu = styled.section`
  z-index: 105;
  padding: ${rem(20)};
  background-color: #ffffff;
  width: 80%;
  position: fixed;
  top: 0;
  right: 0;
  &.open {
    animation: ${menu_open_move} 0.5s forwards;
  }
  &.close {
    animation: ${menu_close_move} 0.5s forwards;
    visibility: hidden;
  }
  .base_line {
    margin-bottom: 2rem;
    border: 1px solid #e9e9e9;
  }
  .scroll {
    overflow-y: auto;
    height: calc(80vh - 240px);
    padding-bottom: ${rem(300)};
  }
`;
export const StyledSideMenuHeader = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  display: flex;
  .btn_close {
    display: inline-block;
    width: ${rem(30)};
    height: ${rem(30)};
    background-image: url('${IMAGE_ROOT}/icons/icon_close.png');
    background-size: 100% auto;
  }
  .btn_setting {
    display: inline-block;
    width: ${rem(30)};
    height: ${rem(30)};
    background-image: url('${IMAGE_ROOT}/icons/icon_setting.png');
    background-size: 100% auto;
  }
`;

/**
 * @name 전체메뉴 사용자 정보 스타일 컴포넌트
 * @param grade 등급
 */
export const StyledSideMenuUserInfo = styled.div<{ grade: string }>`
  position: relative;
  .grade {
    position: absolute;
    top: -5%;
    display: inline-block;
    width: ${rem(37)};
    height: ${rem(24)};
    background-image: url('${IMG_DEFAULT_PATH}/grade_ace.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: ${rem(37)} ${rem(24)};
    ${props => {
      switch (props.grade) {
        case 'ACE':
          return css`
            width: ${rem(37)};
            height: ${rem(24)};
            background-image: url('${IMG_DEFAULT_PATH}/grade_ace.png');
            background-size: ${rem(37)} ${rem(24)};
          `;
        case 'MVG':
          return css`
            width: ${rem(40.5)};
            height: ${rem(24)};
            background-image: url('${IMG_DEFAULT_PATH}/grade_mvg.png');
            background-size: ${rem(40.5)} ${rem(24)};
          `;
        case 'GOLD':
          return css`
            width: ${rem(46)};
            height: ${rem(24)};
            background-image: url('${IMG_DEFAULT_PATH}/grade_gold.png');
            background-size: ${rem(46)} ${rem(24)};
          `;
        case 'VIP':
          return css`
            width: ${rem(33)};
            height: ${rem(24)};
            background-image: url('${IMG_DEFAULT_PATH}/grade_vip.png');
            background-size: ${rem(33)} ${rem(24)};
          `;
        case 'S_ACE':
        case 'S_MVG':
        case 'S_GOLD':
        case 'S_VIP':
        case 'S_ING':
          return css`
            width: ${rem(44)};
            height: ${rem(42)};
            background-image: url('${IMG_DEFAULT_PATH}/grade_${props.grade}.png');
            background-size: ${rem(44)} ${rem(42)};
          `;
        default:
          return css``;
      }
    }}
  }
  .user_info {
    span {
      ${thejamsilFont('light')};
      line-height: ${rem(20)};
      color: ${({ theme }) => theme.colors.gray02};
      font-size: ${rem(14)};
      strong {
        ${thejamsilFont('medium')};
        color: black;
        font-size: ${rem(14)};
        text-decoration: underline;
        text-underline-position: under;
      }
    }
    .join {
      ${thejamsilFont('light')};
      color: ${({ theme }) => theme.colors.gray02};
      font-size: ${rem(12)};
      margin-top: ${rem(6)};
    }
    margin: 0 auto ${rem(20)} ${rem(45)};
    margin-left: ${props => {
      switch (props.grade) {
        case 'ACE':
        case 'MVG':
          return rem(45);
        case 'GOLD':
          return rem(51);
        case 'VIP':
          return rem(40);
        case 'S_ACE':
        case 'S_MVG':
        case 'S_GOLD':
        case 'S_VIP':
        case 'S_ING':
          return rem(52);
        default:
          return;
      }
    }};
  }
`;

export const StyledSideMenutPointSection = styled.div`
  margin-bottom: 2rem;
  .point_info_box {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    grid-template-rows: auto;
    grid-column-gap: 5%;
    .point_name {
      ${thejamsilFont('regular')};
      font-size: ${rem(12)};
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      div {
        width: ${rem(15)};
        height: ${rem(15)};
      }
    }
    .lpoint {
      padding: 0.4rem 0;
    }
    .point {
      ${thejamsilFont('medium')};
      font-size: ${rem(20)};
      color: #0060ff;
      word-wrap: break-word;
      display: flex;
      align-items: center;
      .point_num {
        max-width: 4.5rem;
      }
      button {
        ${thejamsilFont('regular')};
        font-size: ${rem(12)};
        letter-spacing: -0.5px;
        width: auto;
        height: auto;
        padding: 0.5rem;
        border-radius: 0.4rem;
        margin-left: 0.5rem;
      }
    }
  }
`;

export const StyledSideMenuList = styled.div`
  ul {
    ${thejamsilFont('extrabold')};
    font-size: ${rem(20)};
    line-height: ${rem(40)};
    margin-bottom: ${rem(30)};
    li {
      margin-left: ${rem(30)};
      ${thejamsilFont('regular')};
      font-size: ${rem(15)};
      line-height: ${rem(30)};
      span {
        ${thejamsilFont('extrabold')};
        color: ${({ theme }) => theme.colors.red};
        text-decoration: underline;
        text-underline-offset: ${rem(2)};
      }
    }
  }
`;

/**
 * @name 혜택 이용안내 영역 스타일 컴포넌트
 */
export const StyledSideMenuBenefit = styled.div`
  position: relative;
  border: 1px solid #e2e2e2;
  background-color: #f7f7f7;
  padding: ${rem(20)} ${rem(20)} ${rem(15)};
  margin-bottom: ${rem(20)};
  h2 {
    color: #111111;
    ${thejamsilFont('bold')}
    margin-bottom: ${rem(5)};
  }
  ul {
    display: flex;
    width: 85%;
    li {
      display: inline-block;
      padding-right: ${rem(5)};
      margin-right: ${rem(5)};
      :last-child {
        border-right: none;
        padding-right: ${rem(0)};
        margin-right: ${rem(0)};
      }
      img {
        vertical-align: middle;
        width: 100%;
        border-right: 1px solid #c1c1c1;
        padding-right: ${rem(5)};
      }
      &.last_option {
        img {
          border: none;
        }
      }
    }
  }
  .icon {
    position: absolute;
    right: ${rem(10)};
    top: 38%;
    i {
      display: inline-block;
      width: ${rem(20)};
      height: ${rem(20)};
      vertical-align: middle;
    }
  }
`;
