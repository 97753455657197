enum ActionTypes {
  OPEN_MAIN_MENU = 'main/OPEN_MAIN_MENU',
  CLOSE_MAIN_MENU = 'main/CLOSE_MAIN_MENU',
  NONE_MAIN_MENU = 'main/NONE_MAIN_MENU',
  NOMARL_MAIN_MENU = 'main/NOMARL_MAIN_MENU',
  OPEN_MART_SEARCH_POPUP = 'main/OPEN_MART_SEARCH_POPUP',
  CLOSE_MART_SEARCH_POPUP = 'main/CLOSE_MART_SEARCH_POPUP',
}

export default ActionTypes;
